:root {
  --navy-50: #f6f8fc;
  --navy-80: #eef2fa;
  --navy-100: #dee2f2;
  --navy-200: #c9d0e6;
  --navy-300: #b6bdd7;
  --navy-400: #8a92ba;
  --navy-600: #595e8a;
  --navy-800: #424242;
  --navy-900: #1f1f1f;
  --neutral-navy-800: #424242;
  --white: #ffffff;
  --gray-10: #e5e5e5;
  --gray-20: #f0f0f0;
  --gray-50: #e0e0e0;
  --gray-80: #ebebeb;
  --gray-100: #ededed;
  --gray-150: #dbdbdb;
  --gray-200: #d8d8d8;
  --gray-400: #a3a3a3;
  --gray-500: #909090;
  --gray-600: #777777;
  --gray-700: #3e3e3e;
  --green-80: #d4f3f7;
  --green-100: #abe2ea;
  --green-200: #6bc7d3;
  --green-300: #1fcca1;
  --green-350: #399ba7;
  --green-400: #00998c;
  --red-100: #ffe0e0;
  --red-300: #f24d6b;
  --red-350: #e13237;
  --red-400: #d92148;
  --mute-gray: rgba(255, 255, 255, 0.2);
  --voidoc-blue: #3881dc;
  --blue-20: #ddeeff;
  --blue-50: #a3d1ff;
  --blue-100: #3e8bd8;
  --blue-200: #2685fd;
  --border-bottom: #e5e5e5;
}

html,
body {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
  font-family: Avenir Next, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  color: var(--navy-900);
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input:-moz-read-only {
  /* For Firefox */
  border: none;
  color: var(--blue-100);
}

input:read-only {
  border: none;
  color: var(--blue-100);
}

button label {
  cursor: inherit;
}

::-webkit-scrollbar {
  width: 5px; /* 세로축 스크롤바 길이 */
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: gray;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--navy-400);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--navy-400);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--navy-400);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: var(--navy-400);
}

@font-face {
  font-family: 'NotoSansRegular';
  src: url('styles/fonts/NotoSansKR-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'NotoSansMedium';
  src: url('styles/fonts/NotoSansKR-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'NotoSansBold';
  src: url('styles/fonts/NotoSansKR-Bold.otf') format('truetype');
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
}
